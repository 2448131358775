import React, { Component } from 'react';
import Header from '../header';
import Carousel from '../carousel';
import { connect } from 'react-redux';
import Container from '../container';
import WatchVideoBtn from '../hero/watchVideo';

const PopUp = ({ activeEl, next, prev, close }) => {
  return (
    <div className="modal-wrapper">
      <div className="modal-inner">
        <img src={activeEl} alt="" />

        <div className="prev" onClick={prev}>
          <img src="/icons/arrow.svg" alt="button, previous item" />
        </div>

        <div className="next" onClick={next}>
          <img src="/icons/arrow.svg" alt="button, next item" />
        </div>

        <button className="close-modal-btn" onClick={close} />
      </div>
    </div>
  );
};

class GPHero extends Component {
  containerRef = React.createRef();

  state = {
    menuBgVisible: false,
    activeEl: null,
    activeElID: 0,
    modalShown: false
  };

  componentDidMount() {
    this
      .containerRef
      .current
      .children[this.state.activeElID]
      .classList
      .toggle('active');

    this.setState({ activeEl: this.props.imgList[0] });
  }

  toggleBg = () => this.setState(prev => ({ menuBgVisible: !prev.menuBgVisible }));

  togglePopUp = _e => {
    const curOf = document.body.style.overflow;
    document.body.style.overflow = curOf === 'hidden' ? 'auto' : 'hidden';
    this.setState(prev => ({ modalShown: !prev.modalShown }));
  };

  update = (forward = true) => {
    const carousel = this.containerRef.current;
    const active = carousel.querySelector('.active');
    const next = forward ? active.nextElementSibling : active.previousElementSibling;

    if (next) {
      active.classList.toggle('active');
      next.classList.toggle('active');

      const nextWidth = next.offsetWidth;
      const carouselRightOffset = carousel.scrollWidth - carousel.parentElement.clientWidth;
      const spaceLeft = carouselRightOffset - next.offsetLeft - nextWidth;
      const offset = (spaceLeft > -nextWidth) ? next.offsetLeft : carouselRightOffset;

      carousel.style.transform = `translateX(-${offset}px)`;
      this.setState({ activeEl: next.getAttribute('src') });
    }
  };

  next = _e => this.update();
  prev = _e => this.update(false);

  updateFromPopUp = modifier => modifier === 1 ? this.update() : this.update(false);

  render() {
    const { activeEl, menuBgVisible, modalShown, activeElID } = this.state;

    return (
      <div className="gallery-page-hero section-bg-dark">
        <div className={ `menu-bg ${ menuBgVisible ? "active" : "" }` } />

        <Header active={menuBgVisible} toggleBg={this.toggleBg} />

        <Container>
          <div className="content">
            <div className="not-carousel">
              <div className="headers">
                <h1>ГАЛЕРЕЯ</h1>

                <WatchVideoBtn />
              </div>

              <div className="active-item">
                <img src={activeEl} alt="" onClick={this.togglePopUp} height="100%" />
              </div>
            </div>

            <Carousel
              containerRef={this.containerRef}
              images={this.props.imgList}
              next={this.next}
              prev={this.prev}
              activeElID={activeElID}
            />

            {
              modalShown &&
                <PopUp
                  activeEl={activeEl}
                  next={() => this.updateFromPopUp(1)}
                  prev={() => this.updateFromPopUp(-1)}
                  close={this.togglePopUp}
                />
            }
          </div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ imgList }) => ({ imgList });

export default connect(mapStateToProps)(GPHero);
