import '../styles/index.scss';
import React, { Component } from 'react';
import SEO from '../components/seo';
import Footer from '../components/footer';
import GPHero from '../components/gp-hero';

class GalleryPage extends Component {
  componentDidMount() {
    const bodyOverflow = getComputedStyle(document.documentElement).overflow;
    if (bodyOverflow === 'hidden') document.documentElement.style.overflow = 'visible';
  }

  render() {
    return (
      <>
        <div className="gallery-page page">
          <SEO title="Галерея" />

          <GPHero />
          <Footer />
        </div>
        <div id="modal-portal"></div>
      </>
    )
  }
}

export default GalleryPage;
