import React from 'react';

const Carousel = ({ images, containerRef, next, prev }) => (
  <div className="carousel-wrapper">
    <div className="inner">

      <div className="box">
        <div className="images" ref={containerRef}>
          { images.map((img, idx) => (
            // <div className="carousel-item" key={img}>
            <img
              className="carousel-item"
              src={img}
              alt=""
              dataIndex={idx}
              key={img}
              height="232"
            />
            // </div>
          )) }
        </div>
      </div>

      <div className="prev" onClick={prev}>
        <img src="/icons/arrow.svg" alt="button, previous image" /> {/* eslint-disable-line */}
      </div>

      <div className="next" onClick={next}>
        <img src="/icons/arrow.svg" alt="button, next image" /> {/* eslint-disable-line */}
      </div>

    </div>
  </div>
);

export default Carousel;
